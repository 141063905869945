import React from 'react';
import LoadingItem from '../atoms/LoadingItem';

const LoadingResources = ({totalCount}) => {
    const items = [...new Array(totalCount || 3)];

    return (
        <React.Fragment>
            <div className="row d-md-flex w-100 py-md-3 mx-auto">
                { items.map((_, index) => {
                    return (
                        <LoadingItem key={`loading-item-${index}`} />
                    )
                })}
            </div>
        </React.Fragment>
    )
}

export default LoadingResources
