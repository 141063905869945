import HomePage from './components/pages/HomePage';
import ContactPage from './components/pages/ContactPage';
import BooksPage from './components/pages/BooksPage';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/contact_me" component={ContactPage} />
          <Route path="/books" component={BooksPage} />
          <Route path="/" component={HomePage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
