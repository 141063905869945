import React, { useRef, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Alert, Spinner } from 'react-bootstrap';
import FooterNav from '../organisms/FooterNav';
import DOMPurify from 'dompurify';
import styled from 'styled-components';
import PhoneNumberInput from 'react-phone-number-input';
import { sendMessage } from '../../api/message';
import useIsMounted from './../../utils/hooks/isMountedRef';
import 'react-phone-number-input/style.css'
import MailIcon from '../atoms/MailIcon';
import useGaTracker from '../../utils/hooks/useGaTracker';

const StyledPhoneNumberInput = styled(PhoneNumberInput)`
    & .PhoneInputCountry {
        display: none;
    }
`;

const reasonDropdownOptions =[
    { value: 'general_question', text: 'General Question' },
    { value: 'job_offer_fulltime', text: 'Offer Full Time Position' },
    { value: 'job_offer_consulting', text: 'Seeking Consultation' },
    { value: 'job_offer_contract', text: 'Offer Contract-based Position' },
    { value: 'provide_feedback', text: 'Provide Feedback' },
];

const ContactFormSchema = Yup.object()
    .shape({
        name: Yup.string().min(2)
            .matches(/^[A-Za-z .,'-]+$/, 'Your name may only consist of letters, spaces, hyphens, commas, and apostrophe.')
            .required('Your name is a required value.'),
        companyName: Yup.string().matches('/[A-Za-z0-9 .-,\']/'),
        email: Yup.string()
            .email('The email provided does not match this format: yourusername@domain.com')
            .required('Your email is a required value.'),
        phone: Yup.string()
            .required('Your phone is a required value.'),
        preferredMethod: Yup.string()
            .oneOf(['email', 'phone'], 'The option selected is not "email" or "phone".')
            .required('The preferred method of contact must be either "email" or "phone".'),
        reason: Yup.string()
            .oneOf(reasonDropdownOptions.map(({value, text}) => value), 'The reason selected is not an allowed value.')
            .required('Select an option for the reason you want to reach out to me.'),
        message: Yup.string()
            .min('1', 'The message must be at least one character long.')
            .max('500', 'The message must not exceed 500 characters.')
            .required('A message must be provided.'),
    });

const PhoneInput = React.forwardRef(({onChange, onBlur, value}, ref) => {
    return (
        <Form.Control
            type="tel"
            placeholder="(555) 555-5555"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            ref={ref}
        />
    )
});

const SpinnerContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    & .spinner-border {
        width: 4rem;
        height: 4rem;
        border-width: 0.45em;
    }
`;

const StyledEmailLink = styled.a`
    display: inline-block;
    color: currentcolor;

    &:hover {
        color: currentcolor;
    }
`;

const ContactPage = () => {
    const formik = useFormik({
        initialValues: {
            name: '',
            companyName: '',
            email: '',
            phone: '',
            preferredMethod: 'email',
            reason: 'general_question',
            message: ''
        },
        validationSchema: ContactFormSchema,
        onSubmit: ((values) => handleSubmit(values)),
    });
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorsList, setErrorsList] = useState([]);
    const [showServerError, setShowServerError] = useState(false);
    const isMounted = useIsMounted();
    const phoneInputRef = useRef(null);

    useGaTracker();

    const handleSubmitButtonClick = (event) => {
        event.preventDefault();
        isMounted() && setIsLoading(true);
        formik.handleSubmit();
        document.querySelectorAll('.has-error')?.length && document.querySelector('.has-error').scrollIntoView({behavior: 'smooth'});
        isMounted() && setIsLoading(false);
    }

    const handleSubmit = async(values) => {
        try {
            const message = {
                'email_message': {
                    company_name: values.companyName,
                    preferred_method: values.preferredMethod,
                    ...values
                }
            };
            const response = await sendMessage(message);

            if (response?.status !== 200 || (response?.status === 200 && response?.data?.errors)) {
                if (response?.data?.errors?.length) {
                    isMounted() && setShowSuccessMessage(false);
                    isMounted() && setErrorsList(response.data.errors);
                    isMounted() && setShowServerError(false);

                    return;
                }

                isMounted() && setShowServerError(true);
                isMounted() && setErrorsList([]);
                isMounted() && setShowSuccessMessage(false);
                return;
            }

            if (! response?.data?.success) {
                isMounted() && setShowSuccessMessage(false);
                isMounted() && setErrorsList([]);
                isMounted() && setShowServerError(true);

                return;
            }

            formik.reset();
            isMounted() && setErrorsList([]);
            isMounted() && setShowSuccessMessage(true);
            isMounted() && setShowServerError(false);
        } catch (error) {
            isMounted() && setShowServerError(false);
            isMounted() && setErrorsList([]);
            isMounted() && setShowSuccessMessage(true);
            console.log(`Error sending message >>> ${error.message}`, error);
        }
    };

    const {
        name,
        companyName,
        email,
        phone,
        preferredMethod,
        reason,
        message,
    } = formik.values;

    return (
        <React.Fragment>
            <div className="container pb-5">
                <div className="row mt-5 mb-4">
                    <div className="col-md-8">
                    <h1>Have an idea? Have a thought?</h1>

                    <p className="text-muted lead">
                        I'm open to be hired for a full-time position, freelance work, consulting, or 
                        contract based work. Let's work together, plan, and execute the idea so that we 
                        can talk about how far we got and where we will be going!
                    </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                    <Form className="new_email_message" id="new_email_message" action="/contact_me">
                        { isLoading && (
                            <React.Fragment>
                                <SpinnerContainer>
                                    <Spinner animation="border" variant="primary" size="lg" />
                                </SpinnerContainer>
                            </React.Fragment>
                        )}

                        { showSuccessMessage && (
                            <div className="form-row">
                                <div className="col-md-12">
                                    <Alert variant={'success'}>Message was successfully sent.</Alert>
                                </div>
                            </div>
                        )}

                        { showServerError && (
                            <div className="form-row">
                                <div className="col-md-12">
                                    <Alert variant={'danger'}>
                                        Whoops! An error occurred but that's ok. Send me an email instead.
                                        <StyledEmailLink
                                            href="mailto:checokelvin@gmail.com"
                                            className="nav-link p-md-2 p-3"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <MailIcon style={{height: '24px'}} />
                                        </StyledEmailLink>
                                    </Alert>
                                </div>
                            </div>
                        )}

                        { errorsList?.length ? (
                            <div className="form-row">
                                <div className="col-md-12">
                                    <Alert>
                                        <p>There were errors submitting the form:</p>
                                        {errorsList.map((error, index) => {
                                            return (
                                                <p key={index}>{ error }</p>
                                            )
                                        })}
                                    </Alert>
                                </div>
                            </div>
                        ) : null }

                        <div className="form-row">
                            <div className={`form-group col-lg-6 ${formik.errors.name ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="email_message_name">Your name</Form.Label>
                                <Form.Control
                                    placeholder="Enter your full name"
                                    type="text"
                                    name="name"
                                    id="email_message_name"
                                    value={name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                { formik.touched.name && formik.errors.name && (
                                    <Form.Text>
                                        <small style={{color: 'red'}}>{formik.errors.name}</small>
                                    </Form.Text>
                                )}
                            </div>
                            <div className={`form-group col-lg-6 ${formik.errors.companyName ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="email_message_company">
                                    Company name
                                </Form.Label>
                                <sup className="text-muted ml-md-1">(optional)</sup>
                                <Form.Control
                                    placeholder="Enter the name of the company or organization"
                                    type="text"
                                    name="companyName"
                                    id="email_message_company"
                                    value={companyName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                { formik.touched.companyName && formik.errors.companyName && (
                                    <Form.Text>
                                        <small style={{color: 'red'}}>{formik.errors.companyName}</small>
                                    </Form.Text>
                                )}
                            </div>
                        </div>

                        <div className="form-row">
                            <div className={`form-group col-lg-6 ${formik.errors.email ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="email_message_email">Email</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    placeholder="Enter your email address"
                                    type="email"
                                    name="email"
                                    id="email_message_email"
                                    value={email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                { formik.touched.email && formik.errors.email && (
                                    <Form.Text>
                                        <small style={{color: 'red'}}>{formik.errors.email}</small>
                                    </Form.Text>
                                )}
                            </div>
                            <div className={`form-group col-lg-6 ${formik.errors.phone ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="email_message_phone">Contact number</Form.Label>
                                <StyledPhoneNumberInput
                                    value={phone}
                                    defaultCountry="US"
                                    countries={['US']}
                                    limitMaxLength={true}
                                    ref={phoneInputRef}
                                    inputComponent={PhoneInput}
                                    onChange={(value) => {
                                        formik.setFieldValue('phone', value);
                                        formik.setFieldTouched('phone', true);
                                    }}
                                />
                                { formik.touched.phone && formik.errors.phone && (
                                    <Form.Text>
                                        <small style={{color: 'red'}}>{formik.errors.phone}</small>
                                    </Form.Text>
                                )}
                            </div>
                        </div>

                        <div className={`form-group mb-4 ${formik.errors.preferredMethod ? 'has-error' : ''}`}>
                            <p>Preferred contact method</p>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    value="email"
                                    checked={preferredMethod === 'email'}
                                    name="preferredMethod"
                                    id="email_message_preferred_method_email"
                                    onChange={formik.handleChange}
                                />
                                <Form.Label className="form-check-label" htmlFor="email_message_preferred_method">via email</Form.Label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    value="phone"
                                    checked={preferredMethod === 'phone'}
                                    name="preferredMethod"
                                    id="email_message_preferred_method_phone"
                                    onChange={formik.handleChange}
                                />
                                <Form.Label className="form-check-label" htmlFor="email_message_preferred_method">via phone</Form.Label>
                            </div>
                            { formik.touched.preferredMethod && formik.errors.preferredMethod && (
                                    <Form.Text>
                                        <small style={{color: 'red'}}>{formik.errors.preferredMethod}</small>
                                    </Form.Text>
                                )}
                        </div>

                        <div className={`form-group mb-4 ${formik.errors.reason ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="email_message_reason">Reason for contacting me</Form.Label>
                            <Form.Control
                                as="select"
                                className="custom-select"
                                name="reason"
                                id="email_message_reason"
                                value={reason}
                                onChange={formik.handleChange}
                            >
                                { reasonDropdownOptions.map(({value, text}) => {
                                    return (
                                        <option
                                            value={value}
                                            key={Math.floor(19342 + Math.random()*(98422 + 1 - 19432))}
                                        >
                                            {text}
                                        </option>
                                    )
                                })}
                            </Form.Control>
                            { formik.touched.reason && formik.errors.reason && (
                                    <Form.Text>
                                        <small style={{color: 'red'}}>{formik.errors.reason}</small>
                                    </Form.Text>
                                )}
                        </div>

                        <div className={`form-group mb-4 ${formik.errors.message ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="email_message_brief_msg">Additional information</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Provide any details here"
                                name="message"
                                id="email_message_brief_msg"
                                onChange={formik.handleChange}
                                onBlur={(e) => {
                                    const sanitizedValue = DOMPurify.sanitize(e.target.value);
                                    formik.setFieldValue('message', sanitizedValue);
                                }}
                                value={message}
                                style={{resize: 'none'}}
                            />
                            { formik.touched.message && formik.errors.message && (
                                    <Form.Text>
                                        <small style={{color: 'red'}}>{formik.errors.message}</small>
                                    </Form.Text>
                                )}
                        </div>

                        <div className="form-group mb-5">
                            <Button
                                type="button"
                                onClick={handleSubmitButtonClick}
                                className="shadow px-md-4 mr-md-2"
                            >
                                <i className="fas fa-share" /> Send
                            </Button>
                            <a className="btn btn-light" href="/">Return home</a>
                        </div>
                    </Form>
                    </div>
                </div>
            </div>
            <FooterNav />
        </React.Fragment>
    )
}

export default ContactPage
