import React from 'react'
import styled from 'styled-components';

const StyledDiv = styled.div`
    & > h3 {
        font-size: 3.25rem;
        font-weight: 700;
        color: #fff;
    }

    @media (min-width: 576px) {
        & > h3 {
            font-size: 4rem;
        }
    }
`;

export const GreetingsText = () => {
    return (
        <React.Fragment>
            <StyledDiv>
                <h3 className="animate__animated animate__backInRight">Hello!</h3>
            </StyledDiv>
        </React.Fragment>
    )
}
