import React from 'react'
import styled from 'styled-components';

const StyledHeader = styled.h1`
    font-size: 4rem;
    font-weight: 700;
    color: #35f5ff;

    @media (min-width: 447px) {
        & {
            font-size: 4.75rem;
        }
    }
    @media (min-width: 768px) {
        & {
            font-size: 5.5rem;
        }
    }
    @media (min-width: 992px) {
        & {
            font-size: 6.75rem;
        }
    }
`;

const HeaderRow = styled.span`
    display: block;
`;
const SubHeaderRow = styled(HeaderRow)`
    line-height: 2.75rem;
    color: antiquewhite;
    font-size: 2.25rem;
`;
const StyledTag = styled.div`
    & {
        display: block;
    }
    & > ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    & > ul > li {
        display: inline;
        color: #e8a4fe;
    }
    @media (min-width: 996px) and (max-width: 1304px) {
        & {
            display: none;
        }
    }
`;

export const IntroText = () => {
    return (
        <React.Fragment>
            <StyledHeader className="mb-2">
                <HeaderRow className="animate__animated animate__backInDown">
                    I'm Kelvin.
                </HeaderRow>
                <SubHeaderRow className="animate__animated animate__fadeIn animate__delay-2s">
                    A fullstack software engineer
                </SubHeaderRow>
                <SubHeaderRow className="animate__animated animate__fadeIn animate__delay-2s">
                    family man & coding enthusiast
                </SubHeaderRow>
            </StyledHeader>
            <StyledTag className="animate__animated animate__fadeIn animate__delay-2s">
                <ul>
                    <li>AWS-Certified Developer</li>
                </ul>
            </StyledTag>
        </React.Fragment>
    )
}
