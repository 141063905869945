import React, {useState, useEffect } from 'react'
import { ResourceList } from '../ResourceList';
import { getResourceList } from '../../../api/resource';

export const ProjectsSection = () => {
    const [projectsList, setProjectsList] = useState([]);

    useEffect(() => {
        (async () => {
            await getProjectsList();
        })();
    }, []);

    const getProjectsList = async () => {
        try {
            const response = await getResourceList({resource: 'projects', limit: '3'});

            if (response?.data?.error) {
                console.log(`get projects received errors >>> ${response?.data?.error}`, response);
                return;
            }

            setProjectsList(response?.data || []);
        } catch (error) {
            console.log(`frontend error when getting projects list >>> ${error.message}`, error);
        }
    };

    return (
        <React.Fragment>
            <div className="projects w-100 py-md-3 py-5">
                <div className="container pb-md-5">
                    <h3 className="text-center mb-5 mb-md-3">Projects</h3>
                    <ResourceList resourceName={'project'} list={projectsList?.data} />
                    { projectsList?.total_records > 3 && (
                        <div className="w-100 text-right">
                            <a href="/projects">See more &gt;&gt; </a>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};
