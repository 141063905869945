import React, { useEffect, useState } from 'react';
import { getResourceList } from '../../../api/resource';
import styled from 'styled-components';

const StyledSpan = styled.span`
    display: inline-block;
`;

export const SkillsSection = () => {
    const [skillsList, setSkillsList] = useState([]);

    useEffect(() => {
        (async () => {
            await getSkillsList();
        })();
    }, []);

    const getSkillsList = async () => {
        try {
            const response = await getResourceList({resource: 'skills'});

            if (response?.statusText === 'OK' || (response?.statusText === 'OK' && response?.data?.error)) {
                console.log('skills request reached server but received error >>> ', response?.data?.error);
                return;
            }

            setSkillsList(response?.data);
        } catch (error) {
            console.log(`Handle getSkillsList request error >>> ${error.message}`, error);
        }
    };

    const renderSkillsList = () => {
        return skillsList
            .sort((skillA, skillB) => skillA.orderno - skillB.orderno)
            .map((skill) => {
                return (
                    <StyledSpan
                        className="skill-item mr-3"
                        key={skill.id}
                        data-pos={skill.orderno}
                    >
                        {skill.name}
                    </StyledSpan>
                );
            });
    };

    return (
        <React.Fragment>
            <div className="skills w-100 pt-md-3 pt-5 pb-5">
                <div className="container pb-5">
                    <h3 className="mb-5 mb-md-3">Skills</h3>
                    <div className="w-100">
                        { skillsList?.length ? renderSkillsList() : null }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
