import React from 'react'
import {
    WelcomeSection,
    AboutSection,
    ProjectsSection,
    BooksSection,
    SkillsSection
} from '../organisms/home';
import FooterNav from '../organisms/FooterNav';
import useGaTracker from '../../utils/hooks/useGaTracker';

const HomePage = () => {
    useGaTracker();

    return (
        <React.Fragment>
            <WelcomeSection />
            <AboutSection />
            <ProjectsSection />
            <BooksSection />
            <SkillsSection />
            <FooterNav />
        </React.Fragment>
    );
};

export default HomePage;
