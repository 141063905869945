import React from 'react'
import { ProfileImage } from '../../atoms/home/welcome/ProfileImage';
import { ProfileImageBackdrop } from '../../atoms/home/welcome/ProfileImageBackdrop';
import { IntroHeader } from '../../molecules/home/IntroHeader';
import styled from 'styled-components';
import { useSpring } from 'react-spring';
import RoundedSection from '../../atoms/home/welcome/RoundedSection';

const StyledSectionWrapper = styled(RoundedSection)`
    height: 93vh;
    background: #01043b;
`;

const ScrollDown = styled.div`
    position: absolute;
    bottom: 2rem;
    color: rgb(255 255 255 / 85%);
    display: flex;
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;
`;

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const defaultSpringProps = {xy: [0, 0], config: { mass: 10, tension: 550, friction: 140}};

export const WelcomeSection = () => {
    const [springProps, setSpringProps] = useSpring(() => ({...defaultSpringProps}));

    const handleOnMouseMove = ({clientX: x, clientY: y}) => {
        setSpringProps({ xy: calc(x, y)})
    };

    return (
        <React.Fragment>
            <StyledSectionWrapper className="py-5" onMouseMove={handleOnMouseMove}>

                <div className="container d-flex">
                    <IntroHeader />
                    <ProfileImage animationProps={springProps} />
                    <ProfileImageBackdrop animationProps={springProps} />
                </div>
                <ScrollDown
                    id="scroll-down"
                    className="animate__animated animate__bounce animate__infinite animate__slow animate__delay-3s"
                >
                    scroll down
                </ScrollDown>

            </StyledSectionWrapper>
        </React.Fragment>
    );
};
