import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const useGaTracker = () => {
    const [initialized, setInitialized] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (window?.location?.href?.includes('kelvin-checo.dev')) {
            ReactGA.initialize('UA-216601351-1');
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [initialized, location]);

    return <></>;
}

export default useGaTracker
