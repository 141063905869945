import React from 'react'
import styled from 'styled-components';
import { animated } from 'react-spring';

const StyledDiv = styled.div`
    position: absolute;
    background-color: #6fc0ff;
    top: 1rem;
    height: 40rem;
    left: -5rem;
    border-radius: 50% 80%;
    width: 75%;
    max-width: 678px;
    box-shadow: 0 2px 8px 1px rgb(1 4 60);
    display: none;

    @media (min-width: 992px) {
        & {
            display: block;
        }
    }
`;

const StyledDivTwo = styled.div`
    position: absolute;
    background-color: #e8a4ff;
    top: 4rem;
    height: 40rem;
    left: -5rem;
    border-radius: 85% 100% 83% 127%;
    width: 75%;
    max-width: 550px;
    -webkit-transform: rotate(-85deg);
    -ms-transform: rotate( -85deg);
    transform: rotate(-85deg);
    box-shadow: 0 2px 16px 1px rgb(162 110 179);
    display: none;

    @media (min-width: 992px) {
        & {
            display: block;
        }
    }
`;

const transform1 =  (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;
const transform2 =(x, y) => `translate3d(${x / 6}px,${y / 6}px, 0) rotate(-85deg)`;

const AnimatedDiv = animated(StyledDiv);
const AnimatedDivTwo = animated(StyledDivTwo);

export const ProfileImageBackdrop = ({animationProps}) => {
    return (
        <React.Fragment>
            <AnimatedDiv style={{transform: animationProps?.xy?.interpolate(transform1)}} />
            <AnimatedDivTwo style={{transform: animationProps?.xy?.interpolate(transform2)}} />
        </React.Fragment>
    )
}
