import React from 'react'
import styled from 'styled-components';
import RoundedSection from '../../atoms/home/welcome/RoundedSection';

const SectionHeader = styled.h2`
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    color: #01043b;
`;

export const AboutSection = () => {
    return (
        <React.Fragment>

            <RoundedSection className="about my-md-3 py-5">
                <div className="container pb-md-5">
                    <div className="col-xl-4 col-md-8">
                        <SectionHeader className="mb-5">
                            What am I doing today?
                        </SectionHeader>
                    </div>
                    <div className="col-md-8">
                        <p className="lead">
                            Today, I work as a Senior Software Engineer at MedPut overseeing the full deployment cycle of each
                            sprint for our SaaS product, providing mentorship for software engineers that join our team, and 
                            contributing to the overall growth of the business.
                        </p>
                        <p className="lead">
                            While I completed the AWS Certified Developer Associate Exam back in March 2021, I am currently studying 
                            for the Machine Learning Specialty exam.
                        </p>
                        <p className="lead">
                            Along with studying for the ML exam, I work on improving my programming skills by reading books and 
                            solving problems on HackerRank and CodeChef. All when I am not spending it with my family and friends.
                        </p>
                    </div>
                </div>
            </RoundedSection>

            <RoundedSection className="about my-md-3 py-5" style={{backgroundColor: 'antiquewhite'}}>
                <div className="container pb-md-5">
                    <div className="row px-3">
                        <div className="col-xl-4 col-md-8">
                            <SectionHeader className="mb-5">
                                How I started programming
                            </SectionHeader>
                        </div>
                    </div>
                    <div className="row px-3">
                        <div className="col-md-6">
                            <p className="lead mb-4">
                                I started to learn web development at the time when social media was at its 
                                infancy and people were able to modify their profile by inserting chunks of CSS into 
                                a comment field while editing their profile. I remember being captivated by the 
                                complete design overhaul of someone's profile that I began to read the source 
                                code (ctrl - u). Shortly after, I was customizing my friend's profiles.
                            </p>
                            <p className="lead mb-4">
                                In 2015, after working in the healthcare industry for about 10 years (at the time), I made the 
                                decision to dedicate my hours after work to improve my web development skills.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p className="lead mb-4">
                                My first big project was developing a store-front website in PHP with a fully custom 
                                content management system. I continued working with people who were starting up their 
                                businesses who sought out to build/establish their presence on the web through my 
                                Web Development start up.
                            </p>
                            <p className="lead mb-4">
                                I have designed and developed static websites, custom CMS's, e-commerce websites, utilized 
                                WordPress, and developed APIs using back end technologies like Ruby on Rails and PHP. I have 
                                also worked on interdisciplinary teams both in the healthcare and web development field. 
                            </p>
                        </div>
                    </div>
                </div>
            </RoundedSection>
        </React.Fragment>
    );
};
