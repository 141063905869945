import axios from "axios";
import { BACKEND_API } from "./config";

export const getResourceList = async({resource, limit, orderBy}) => {
    try {
        let queryString = '';
        let queryParams = [];

        if (typeof resource === 'undefined' || resource === null || resource === '') {
            throw new Error('The name of the resource must be provided as a property in the argument.');
        }

        if (limit !== null && typeof limit !== 'undefined') {
            queryParams.push(`limit=${limit}`);
        }

        if (orderBy !== null && typeof orderBy !== 'undefined') {
            queryParams.push(`order_by=${orderBy}`);
        }

        queryString += queryParams.length ? '?'.concat(queryParams.join('&')) : '';

        return await axios.get(`${BACKEND_API}/${resource}${queryString}`);
    } catch (error) {
        console.log(`api error fetching ${resource} >>> ${error.message}`, error);
    }
};