import React from 'react'
import styled from 'styled-components';
import GithubIcon from '../atoms/GithubIcon';
import InstagramIcon from '../atoms/InstagramIcon';
import LinkedInIcon from '../atoms/LinkedInIcon';
import MailIcon from '../atoms/MailIcon';

const StyledFooterNav = styled.footer`
    background: #01043b !important;
`;

const FooterNav = () => {
    return (
        <React.Fragment>
            <StyledFooterNav className="footer-nav navbar navbar-expand navbar-dark bg-dark">
                <div className="container">
                    <a
                        className="nav-link contact-link text-light"
                        href="/contact_me"
                        style={{
                            color: 'rgba(255,255,255,.5)',
                            fontWeight: 'bold'
                        }}
                    >
                        Work with me
                    </a>
                    <ul className="navbar-nav flex-row ml-md-auto d-md-flex">
                        <li className="nav-item">
                            <a
                                href="https://github.com/kcheco"
                                className="nav-link p-md-2 p-3"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <GithubIcon style={{height: '24px'}} />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href="https://linkedin.com/in/kelvincheco25"
                                className="nav-link p-md-2 p-3"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <LinkedInIcon style={{height: '24px'}} />
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href="https://instagram.com/kelvin.checo"
                                className="nav-link p-md-2 p-3"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <InstagramIcon style={{height: '24px'}}/>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="mailto:checokelvin@gmail.com" className="nav-link p-md-2 p-3" target="_blank" rel="noreferrer">
                                <MailIcon style={{height: '24px'}} />
                            </a>
                        </li>
                    </ul>
                    <span className="navbar-text d-none ml-md-3">© All rights reserved.</span>
                </div>
            </StyledFooterNav>
        </React.Fragment>
    );
};

export default FooterNav;
