import axios from 'axios';
import { BACKEND_API } from './config';

export const getImage = async ({id, resource}) => {
    try {
        if (id === null || typeof id === 'undefined') {
            throw new Error('The id parameter is required when fetching an image.');
        }

        if (resource === null || typeof resource === 'undefined') {
            throw new Error('The resource parameter is required when fetching an image.');
        }

        return await axios.get(`${BACKEND_API}/images/${id}?type=${resource}`);
    } catch (error) {
        console.log(`api error fetching image >>> ${error.message}`, error);
    }
};