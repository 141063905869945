import React from 'react'
import './ListItem.css';

const ListItem = ({item, image, resourceName}) => {
    return (
        <div className={`col-md-4 py-md-4 mb-3 list-item ${resourceName}`}>
            { image?.image_url ? (
                <div className="rounded-corners overflow-hidden shadow-sm bg-light mb-md-3">
                    <a target="_blank" rel="noreferrer" href={item.link}>
                        <img alt={item.title} title={item.title} src={image.image_url} />
                    </a>
                </div>
            ) : null }
            <div className="links">
                <div className="row px-3">
                <p className="text-muted mb-md-2">{item.title}</p>
                </div>
            </div>
        </div>
    )
}

export default ListItem
