import React from 'react'
import ListItemContainer from './ListItemContainer';

export const ResourceList = ({resourceName, list}) => {
    return (
        <React.Fragment>
            <div className="row d-md-flex w-100 py-md-3 mx-auto">
                { list?.length ? list.map((item) => {
                    return (
                        <ListItemContainer
                            resourceName={resourceName}
                            item={item}
                            key={item.id}
                        />
                    );
                }) : null }
            </div>
        </React.Fragment>
    )
}
