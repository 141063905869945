import React from 'react'
import { ContactMeButton } from '../../atoms/home/welcome/ContactMeButton'
import { GreetingsText } from '../../atoms/home/welcome/GreetingsText'
import { IntroText } from '../../atoms/home/welcome/IntroText'
import styled from 'styled-components';

const IntroWrapper = styled.div`
    position: relative;
    z-index: 2;
    margin-top: 27px;

    @media (min-width: 567px) {
        & {
            margin-top: 54px;
        }
    }
`;

export const IntroHeader = () => {
    return (
        <React.Fragment>
            <IntroWrapper className="ml-auto">
                <GreetingsText />
                <IntroText />
                <ContactMeButton />
            </IntroWrapper>
        </React.Fragment>
    )
}
