import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const StyledButton = styled(Button)`
    border: 0;
    border-radius: 30px;
    background: linear-gradient(to left, #a925d6,#35f5ff);
    padding: 14px 41px !important;Í
    font-size: 1.25rem;
    font-weight: bold;
`;

const StyledBubble = styled.div`
    background: #fff;
    color: #01043b;
    width: 100%;
    max-width: 250px;
    padding: 5px 11px;
    margin-top: 11px;
    border-radius: 11px;
    position: absolute;
    left: 21px;
    opacity: 0;
    transition: opacity 0.5ms;

    &::before {
        content: "";
        position: absolute;
        top: calc((11px / 2) * -1);
        width: 11px;
        height: 11px;
        left: 34px;
        transform: rotate(45deg);
        background: #fff;
        z-index: -1;
        opacity: 0;
        transition: opacity 0.5ms;
    }

    &.show-bubble,
    &.show-bubble::before {
        opacity: 1;
    }
`;

const ButtonText = [
    'the process of building this website',
    'the architecture of this website',
    'how I can bring value to your business as a member of your IT team',
    'how I can evaluate your codebase'
];

export const ContactMeButton = () => {
    const [isButtonHovered, setIsButtonHovered] = useState(false);
    const [buttonText, setButtonText] = useState(ButtonText[0]);

    const handleOnMouseEnter = () => {
        setIsButtonHovered(true)
        setButtonText(ButtonText[Math.floor(Math.random() * ButtonText.length)]);
    };

    const handleOnMouseLeave = () => {
        setIsButtonHovered(false)
    };

    return (
        <React.Fragment>
            <div className="cta mt-3 my-5" style={{position: 'relative'}}>
                <StyledButton
                    variant='primary'
                    size='large'
                    className="shadow text-white px-4 py-2 animate__animated animate__fadeIn animate__delay-2s"
                    href="/contact_me"
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                >
                    <span>Contact Me</span>
                </StyledButton>
                <StyledBubble className={isButtonHovered ? 'show-bubble' : ''}>
                    { `Ask me about ${buttonText}!` }
                </StyledBubble>
            </div>
        </React.Fragment>
    )
}
