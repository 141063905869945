import React from 'react'
import styled from 'styled-components';
import { animated } from 'react-spring';

const ImageContainer = styled.div`
    position: absolute;
    display: none;
    top: 10rem;
    z-index: 1;
    left: 0.5rem;
    width: 50%;
    max-width: 417px;

    & > img {
        width: 100%;
        border: 5px solid #c19bce;
        border-radius: 50%;
        box-shadow: 0 2px 18px 0px rgb(108 86 115) !important;
        filter: contrast(2.25);
    }

    @media (min-width: 992px) {
        & {
            display: block;
            width: 40%;
        }
    }

    @media (min-width: 1100px) {
        & {
            width: 34%;
        }
    }

    @media (min-width: 1100px) {
        & {
            width: 30%;
        }
    }

    @media (min-width: 1300px) {
        & {
            width: 25%;
        }
    }
`;
const StyledDiv = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    // max-width: 360px;
    // max-height: 360px;
    background-color: #35abff40;
    top: 0;
    left: 0;
    border-radius: 50%;
`;

const AnimatedImageContainer = animated(ImageContainer);
const transform1 = (x, y) => `translate3d(${x / 3.5}px,${y / 3.5}px,0)`;

export const ProfileImage = ({animationProps}) => {
    return (
        <React.Fragment>
            <AnimatedImageContainer style={{transform: animationProps?.xy?.interpolate(transform1)}}>
                <img
                    className="shadow-sm"
                    src={`${window.location.origin}/assets/images/profile-image.png`}
                    alt="ChecoDesigns, Profile"
                    draggable="false"
                />
                <StyledDiv />
            </AnimatedImageContainer>
        </React.Fragment>
    )
}
