import React, { useEffect, useState } from 'react';
import ListItem from './ListItem';
import { getImage } from './../../api/image';
import LoadingItem from '../atoms/LoadingItem';
import useIsMounted from '../../utils/hooks/isMountedRef';

const ListItemContainer = ({resourceName, item}) => {
    const [image, setImage] = useState({});
    const isMounted = useIsMounted();

    useEffect(() => {
        (async () => {
            if (typeof item !== 'undefined' && item !== null) {
                await getImageFromApi();
            }
        })();
        // eslint-disable-next-line
    }, []);

    const getImageFromApi = async () => {
        try {
            const response = await getImage({id: item.id, resource: resourceName});

            if (response?.status !== 200 || (response?.status === 200 && response?.data?.error)) {
                console.log(`There was an error loading image >>> ${response?.data?.error}`);
                return;
            }

            isMounted() && setImage(response.data);
        } catch (error) {
            console.log(`frontend error getting image >>> ${error.message}`, error);
        }
    };

    return (
        <React.Fragment>
            { Object.keys(image).length
                ? <ListItem image={image} item={item} resourceName={resourceName} />
                : <LoadingItem />
            }
        </React.Fragment>
    )
}

export default ListItemContainer;
