import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
    overflow: overlay;
    overflow-y: hidden;
    padding: 0;
    margin: 14px;
    border-radius: 21px;
    position: relative;

    @media (min-width: 489px) {
        & {
            margin: 30px;
            border-radius: 30px;
        }
    }
`;

const RoundedSection = (props) => {
    return (
        <StyledDiv
            className={props?.className ? props.className : ''}
            style={props?.style ? props.style : {}}
            onMouseMove={typeof props?.onMouseMove === 'function' ? props.onMouseMove : null }
        >
            {props.children}
        </StyledDiv>
    )
}

export default RoundedSection
