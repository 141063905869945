import React, {useEffect, useState} from 'react'
import { getResourceList } from '../../api/resource';
import FooterNav from '../organisms/FooterNav';
import { ResourceList } from '../organisms/ResourceList';
import LoadingResources from '../organisms/LoadingResources';
import { Link } from 'react-router-dom';
import useGaTracker from '../../utils/hooks/useGaTracker';

const BooksPage = () => {
    const [booksList, setBooksList] = useState([]);
    useGaTracker();

    useEffect(() => {
        (async () => {
            await getBooksList();
        })();
    }, []);

    const getBooksList = async () => {
        try {
            const response = await getResourceList({resource: 'books'});

            if (response?.data?.error) {
                console.log(`get books received errors >>> ${response?.data?.error}`, response);
                return;
            }

            setBooksList(response?.data || []);
        } catch (error) {
            console.log(`frontend error when getting books list >>> ${error.message}`, error);
        }
    };

    return (
        <React.Fragment>
            <div className="container">
                <div className="w-100 my-3 py-3">
                    <h3 className="my-3">List of books I've read</h3>
                    <Link to="/">Return to Home</Link>
                    { booksList?.data?.length
                        ? <ResourceList resourceName={'book'} list={booksList?.data} />
                        : <LoadingResources totalCount={9} />
                    }
                </div>
            </div>
            <FooterNav />
        </React.Fragment>
    )
}

export default BooksPage;
