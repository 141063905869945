import React from 'react'
import Skeleton from 'react-loading-skeleton';

const LoadingItem = () => {
    return (
        <div className="col py-md-4 mb-3">
            <Skeleton
                height={'192px'}
                width={'340px'}
                style={{borderRadius: '21px'}}
            />
        </div>
    )
}

export default LoadingItem
